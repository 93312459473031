import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { AddressTypes, LocationDetailsType } from 'shared.types';

export const getCity = (placesResponse: google.maps.GeocoderResult): string => {
  const city = placesResponse.address_components.find((component) =>
    component.types.includes('locality'),
  );
  if (city) return city.long_name;

  const sublocality = placesResponse.address_components.find((component) =>
    component.types.includes('sublocality_level_1'),
  );
  if (sublocality) return sublocality.long_name;

  return '';
};

export const getState = (
  placesResponse: google.maps.GeocoderResult,
): string => {
  const state = placesResponse.address_components.find((component) =>
    component.types.includes('administrative_area_level_1'),
  );
  return state?.long_name || '';
};

export const getPostalCode = (
  placesResponse: google.maps.GeocoderResult,
): string => {
  const postalCode = placesResponse.address_components.find((component) =>
    component.types.includes('postal_code'),
  );
  return postalCode?.long_name || '';
};

export const getCountryCode = (
  placesResponse: google.maps.GeocoderResult,
): string => {
  const country = placesResponse.address_components.find((component) =>
    component.types.includes('country'),
  );
  return country?.short_name || '';
};

export const getAddress = (
  placesResponse: google.maps.GeocoderResult,
): string => placesResponse.formatted_address;

export const getAirportCode = (placesResponse: google.maps.GeocoderResult): string | undefined => {
  try
  {
    if (placesResponse.types.includes('airport'))
    {
      // Check for IATA code enclosed in ()
      const match = placesResponse.formatted_address.match(/\(([^)]+)\)/);
      if (match){ return match[1]; }
      
      const airport = placesResponse.address_components.find((component) =>
        component.types.includes('airport')
      );
      if (airport?.long_name)
      {
        // Server side will look up the IATA Code for the given airport
        return airport?.long_name;
      }
    }
  }
  catch(err)
  {
    return undefined;
  }
  return undefined;
};

export const getLocationFromGooglePlacesId = async (
  googlePlacesId: string,
  locationDescription: string,
  addressType: AddressTypes,
): Promise<LocationDetailsType> => {
  const locationData = await geocodeByPlaceId(googlePlacesId);
  const lat = locationData[0].geometry.location.lat();
  const lng = locationData[0].geometry.location.lng();
  const location: LocationDetailsType = {
    addressType: addressType,
    address: locationDescription,
    city: getCity(locationData[0]),
    state: getState(locationData[0]),
    postalCode: getPostalCode(locationData[0]),
    countryCode: getCountryCode(locationData[0]),
    airportCode: getAirportCode(locationData[0]),
    latitude: lat,
    longitude: lng,
    googlePlacesId: googlePlacesId,
  };

  return location;
};
